/* eslint-disable react/no-find-dom-node */
// @TODO split to multiple components
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import { Formik } from 'formik';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import Typography from '@material-ui/core/Typography/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  Footer,
  Layout,
} from '../containers';
import {
  Container,
  FileDropdownContainer,
  GradientBackground,
  StyledButton,
  StyledForm,
  StyledFormGroup,
  StyledFormGroupLabel,
  StyledTextField,
} from '../styled';
import {
  CheckboxChecked,
  CheckboxUnchecked,
} from '../icons';

import { COLORS } from '../utils/constants';

import 'filepond/dist/filepond.min.css';

registerPlugin(FilePondPluginFileValidateType);

class FormPage extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  state = {
    TypeOfFilmLabelWidth: 0,
    LengthOfFilmLabelWidth: 0,
    FieldsOfExplLabelWidth: 0,
    withFile: false,
  };

  componentDidMount() {
    this.setState({
      TypeOfFilmLabelWidth: ReactDOM.findDOMNode(this.TypeOfFilmInputLabelRef).offsetWidth + 5,
      LengthOfFilmLabelWidth: ReactDOM.findDOMNode(this.LengthOfFilmInputLabelRef).offsetWidth + 10,
      FieldsOfExplLabelWidth: ReactDOM.findDOMNode(this.FieldsOfExplInputLabelRef).offsetWidth + 15,
    });
  }

  handleChangeSelect = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeCheckbox = name => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleChangeText = name => (event) => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const {
      TypeOfFilmLabelWidth,
      LengthOfFilmLabelWidth,
      FieldsOfExplLabelWidth,
      withFile,
    } = this.state;
    const { data } = this.props;
    const { allMarkdownRemark } = data;
    const { edges } = allMarkdownRemark;
    const { contact } = edges.filter(el => el.node.frontmatter.contact)[0].node.frontmatter;

    return (
      <Layout>
        <GradientBackground style={{
          paddingBottom: '3.75rem', paddingTop: '8.125rem', display: 'flex', alignItems: 'center',
        }}
        >
          <Container>
            <div style={{ alignItems: 'flex-start' }}>
              <Typography variant="h3" gutterBottom>
                Get a quote
              </Typography>
              <Formik
                initialValues={{
                  typeOfFilm: '',
                  lengthOfFilm: '',
                  fieldsOfExploitation: '',
                  cleanUp: false,
                  compositing: false,
                  motionDesign: false,
                  noiseReduction: false,
                  advancedStabilization: false,
                  nameCompany: '',
                  email: '',
                  phone: '',
                  description: '',
                  termsAccepted: false,
                  fileName: '',
                  fileId: '',
                  fileServerPath: '',
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = 'Required';
                  }
                  if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                  ) {
                    errors.email = 'Invalid email address';
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  /* eslint-disable no-console */
                  setSubmitting(true);
                  fetch(
                    'https://storage.progso.com/lv.php',
                    {
                      method: 'POST',
                      headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                      },
                      mode: 'no-cors',
                      body: JSON.stringify(values),
                    },
                  )
                    .then((response) => {
                      if (response.status === 0 || response.status === 400) {
                        navigate('/thank-you');
                      } else {
                        console.log('post form error', response);
                      }
                    })
                    .catch(error => console.log('error', error));
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <StyledForm name="LV get a quote" onSubmit={handleSubmit}>
                    <StyledFormGroup row>
                      <FormControl variant="outlined">
                        <InputLabel
                          ref={(ref) => {
                            this.TypeOfFilmInputLabelRef = ref;
                          }}
                          htmlFor="type-of-film"
                        >
                            Type of film
                        </InputLabel>
                        <Select
                          disabled={isSubmitting}
                          value={values.typeOfFilm}
                          onChange={handleChange}
                          IconComponent={IconExpandMore}
                          input={(
                            <OutlinedInput
                              labelWidth={TypeOfFilmLabelWidth}
                              name="typeOfFilm"
                              id="type-of-film"
                            />
                            )}
                        >
                          <MenuItem value="">Type of film</MenuItem>
                          <MenuItem value="commercial TV/ Internet">commercial TV/ Internet</MenuItem>
                          <MenuItem value="event or conference video">event or conference video</MenuItem>
                          <MenuItem value="feature / documentary film">feature / documentary film</MenuItem>
                          <MenuItem value="short video for social media">short video for social media</MenuItem>
                          <MenuItem value="other">other</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined">
                        <InputLabel
                          ref={(ref) => {
                            this.LengthOfFilmInputLabelRef = ref;
                          }}
                          htmlFor="length-of-film"
                        >
                            Length of film
                        </InputLabel>
                        <Select
                          disabled={isSubmitting}
                          value={values.lengthOfFilm}
                          onChange={handleChange}
                          IconComponent={IconExpandMore}
                          input={(
                            <OutlinedInput
                              labelWidth={LengthOfFilmLabelWidth}
                              name="lengthOfFilm"
                              id="length-of-film"
                            />
                            )}
                        >
                          <MenuItem value="">Length of film</MenuItem>
                          <MenuItem value="less than 20s">- &lt; 20”</MenuItem>
                          <MenuItem value="less than 60s">- &lt; 60”</MenuItem>
                          <MenuItem value="1 to 5 minutes">- 1’ - 5’</MenuItem>
                          <MenuItem value="5 to 10 minutes">- 5’ - 10’</MenuItem>
                          <MenuItem value="10 to 30 minutes">- 10’ - 30’</MenuItem>
                          <MenuItem value="over 30 minutes">- &gt; 30’</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl variant="outlined">
                        <InputLabel
                          ref={(ref) => {
                            this.FieldsOfExplInputLabelRef = ref;
                          }}
                          htmlFor="fields-of-exploitation"
                        >
                            Fields of exploitation
                        </InputLabel>
                        <Select
                          disabled={isSubmitting}
                          value={values.fieldsOfExploitation}
                          onChange={handleChange}
                          IconComponent={IconExpandMore}
                          input={(
                            <OutlinedInput
                              labelWidth={FieldsOfExplLabelWidth}
                              name="fieldsOfExploitation"
                              id="fields-of-exploitation"
                            />
                            )}
                        >
                          <MenuItem value="">Fields of exploitation</MenuItem>
                          <MenuItem value="cinema/Tv">cinema/Tv</MenuItem>
                          <MenuItem value="online">online</MenuItem>
                          <MenuItem value="internal needs">internal needs</MenuItem>
                        </Select>
                      </FormControl>
                    </StyledFormGroup>
                    <StyledFormGroupLabel>
                        Additional services
                    </StyledFormGroupLabel>
                    <StyledFormGroup row withCheckbox>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            disabled={isSubmitting}
                            id="cleanUp"
                            checked={values.cleanUp}
                            onChange={handleChange}
                            value="cleanUp"
                            icon={<CheckboxUnchecked />}
                            checkedIcon={<CheckboxChecked />}
                            color="secondary"
                          />
                          )}
                        label="clean up"
                      />
                      <FormControlLabel
                        control={(
                          <Checkbox
                            disabled={isSubmitting}
                            id="compositing"
                            checked={values.compositing}
                            onChange={handleChange}
                            value="compositing"
                            icon={<CheckboxUnchecked />}
                            checkedIcon={<CheckboxChecked />}
                            color="secondary"
                          />
                          )}
                        label="compositing"
                      />
                      <FormControlLabel
                        control={(
                          <Checkbox
                            disabled={isSubmitting}
                            id="motionDesign"
                            checked={values.motionDesign}
                            onChange={handleChange}
                            value="motionDesign"
                            icon={<CheckboxUnchecked />}
                            checkedIcon={<CheckboxChecked />}
                            color="secondary"
                          />
                          )}
                        label="motion design"
                      />
                      <FormControlLabel
                        control={(
                          <Checkbox
                            disabled={isSubmitting}
                            id="noiseReduction"
                            checked={values.noiseReduction}
                            onChange={handleChange}
                            value="noiseReduction"
                            icon={<CheckboxUnchecked />}
                            checkedIcon={<CheckboxChecked />}
                            color="secondary"
                          />
                          )}
                        label="noise reduction"
                      />
                      <FormControlLabel
                        control={(
                          <Checkbox
                            disabled={isSubmitting}
                            id="advancedStabilization"
                            checked={values.advancedStabilization}
                            onChange={handleChange}
                            value="advancedStabilization"
                            icon={<CheckboxUnchecked />}
                            checkedIcon={<CheckboxChecked />}
                            color="secondary"
                          />
                          )}
                        label="advanced stabilization"
                      />
                    </StyledFormGroup>
                    <StyledFormGroup row>
                      <StyledTextField
                        disabled={isSubmitting}
                        id="nameCompany"
                        label="Name / Company"
                        value={values.nameCompany}
                        onChange={handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                      <StyledTextField
                        disabled={isSubmitting}
                        id="email"
                        required
                        label="E-mail address"
                        value={values.email}
                        onChange={handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                      <StyledTextField
                        disabled={isSubmitting}
                        id="phone"
                        label="Mobile"
                        value={values.phone}
                        onChange={handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    </StyledFormGroup>
                    <StyledFormGroup row textArea>
                      <StyledTextField
                        disabled={isSubmitting}
                        id="description"
                        label="Description"
                        multiline
                        rows="4"
                        margin="normal"
                        variant="outlined"
                        value={values.description}
                        onChange={handleChange}
                      />
                    </StyledFormGroup>
                    <StyledFormGroup>
                      <StyledFormGroupLabel>
                          Upload your video sample
                      </StyledFormGroupLabel>
                      <FileDropdownContainer
                        withFile={withFile}
                      >
                        <FilePond
                          server={{
                            url: 'https://storage.progso.com/lv.php',
                            process: {
                              onload: (response) => {
                                const regex = /\/tmp\/php(.+[a-zA-Z0-9])/gm;
                                setFieldValue('fileServerPath', response.match(regex)[0]);
                              },
                            },
                          }}
                          acceptedFileTypes={['video/*', 'image/*']}
                          onaddfile={(error, file) => {
                            setFieldValue('fileName', file.filename);
                            setFieldValue('fileId', file.id);
                            this.setState({ withFile: true });
                          }}
                        />
                      </FileDropdownContainer>
                    </StyledFormGroup>
                    <StyledFormGroupLabel size="2" style={{ marginTop: '2rem' }}>
                      <span style={{ color: COLORS.red }}>*</span>
                        Required fields
                    </StyledFormGroupLabel>
                    <StyledFormGroup>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            disabled={isSubmitting}
                            id="termsAccepted"
                            required
                            checked={values.termsAccepted}
                            onChange={handleChange}
                            value="termsAccepted"
                            color="primary"
                            style={
                                { color: values.termsAccepted ? COLORS.green : COLORS.red }
                              }
                          />
                          )}
                        label="I accept the terms and conditions" // @TODO: only label should be clickable, not 100% container
                      />
                    </StyledFormGroup>
                    <div style={{ display: 'inline-block', position: 'relative' }}>
                      <StyledButton type="submit" variant="contained" spaceTop={1} disabled={isSubmitting}>
                          Send
                      </StyledButton>
                      {
                        isSubmitting && (
                          <CircularProgress
                            size={24}
                            style={{
                              color: COLORS.red,
                              position: 'absolute',
                              top: '50%',
                              right: 16,
                              marginTop: -4,
                            }}
                          />
                        )
                      }
                    </div>
                  </StyledForm>
                )}
              </Formik>
            </div>
          </Container>
        </GradientBackground>
        <Footer contactSectionContent={contact} />
      </Layout>
    );
  }
}

export default FormPage;

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            contact {
              contact_address_heading
              contact_address_email
              contact_address_website
              contact_address_street
              contact_address_city
              contact_social_heading
              contact_copyright_informations
              contact_cookies_information
              contact_cookies_link
              contact_gdpr_informations
              contact_gdpr_link
            }
            form {
              form_heading
            }
          }
        }
      }
    }
  }
`;
